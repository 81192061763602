import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'

/*
import myFont from 'three/examples/fonts/helvetiker_regular.typeface.json'
*/

import { FontLoader} from 'three/examples/jsm/loaders/FontLoader.js'

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

// Gestione movimenti oggetti
import gsap from 'gsap'


/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Helper
const axisHelper = new THREE.AxesHelper(100)
scene.add(axisHelper)


/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matCaps      = textureLoader.load('/textures/matcaps/10.png')

const colori = [
    textureLoader.load('/textures/matcaps/1.png'),
    textureLoader.load('/textures/matcaps/2.png'),
    textureLoader.load('/textures/matcaps/3.png'),
    textureLoader.load('/textures/matcaps/4.png'),
    textureLoader.load('/textures/matcaps/5.png'),
    textureLoader.load('/textures/matcaps/6.png'),
    textureLoader.load('/textures/matcaps/7.png'),
    textureLoader.load('/textures/matcaps/8.png'),
    textureLoader.load('/textures/matcaps/9.png'),
    textureLoader.load('/textures/matcaps/10.png'),
    textureLoader.load('/textures/matcaps/11.png'),
];


/**
 * Fonts
 */

const fontLoader   =   new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        console.log("font.loades")
        const textGeometry = new TextGeometry(
            "Hello 186c6c",
            {
                font: font ,
                size: 0.5 ,
                height: 0.1, 
                curveSegments : 4 , 
                bevelEnabled : true ,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 3
            }
        )
        textGeometry.computeBoundingBox()
        textGeometry.center()
        const col = Math.floor(Math.random() *11);
        
        console.log("Colore Scritta "+col)

        const materiale = new THREE.MeshMatcapMaterial()
        materiale.matcap =  colori[col]
        const myText  = new THREE.Mesh(textGeometry, materiale)
        scene.add(myText)   

    }
)


/**
 * Object
 */

const colore = Math.floor(Math.random() *11);

console.log("Colore oggetti "+colore)

const cuboGeometria         =  new THREE.BoxGeometry(0.2, 0.2, 0.2)
const portaGeometria         =  new THREE.BoxGeometry(1, 1, 1)
const ciambellaGeometria    =  new THREE.TorusGeometry(0.1, 0.05, 20, 45)
const materialeOggetti      =  new THREE.MeshMatcapMaterial({matcap: colori[colore]})

function CreateCiambella() {
    
    const ciambella = new THREE.Mesh(ciambellaGeometria, materialeOggetti)
    
    ciambella.position.x = (Math.random() -0.5) * 10 
    ciambella.position.y = (Math.random() -0.5) * 10
    ciambella.position.z = (Math.random() -0.5) * 10

    ciambella.rotation.x = Math.random() * Math.PI 
    ciambella.rotation.z = Math.random() * Math.PI 
    
    const scala = Math.random()

    ciambella.scale.x = scala
    ciambella.scale.y = scala 
    ciambella.scale.z = scala


    return ciambella
}

function CreateCube(posX, posY) {
    
    const cubo = new THREE.Mesh(cuboGeometria, materialeOggetti)
    
    
    cubo.position.x = posX
    cubo.position.y = posY
    cubo.position.z = 1
    
    /*
    cubo.rotation.x = Math.random() * Math.PI 
    cubo.rotation.z = Math.random() * Math.PI 
    
    const scala = Math.random()
    cubo.scale.x = scala
    cubo.scale.y = scala 
    cubo.scale.z = scala
    */
    return cubo
}

function CreatePorta(posX, posY) {
    
    const cubo = new THREE.Mesh(portaGeometria, materialeOggetti)
    
    
    cubo.position.x = posX
    cubo.position.y = posY
    cubo.position.z = 1
    
    /*
    cubo.rotation.x = Math.random() * Math.PI 
    cubo.rotation.z = Math.random() * Math.PI 
    
    const scala = Math.random()
    cubo.scale.x = scala
    cubo.scale.y = scala 
    cubo.scale.z = scala
    */
    return cubo
}

console.log(865 % 100);

console.time("cubo")

for(let i = 0 ; i < 1000 ; i ++ ) {

    const posizione = calcola(i) 
    
    
    const cubo =  CreateCube(posizione.x,posizione.y)
    scene.add(cubo)
    explode(cubo)
//    /*
//    const porta = CreatePorta(posizione.x,posizione.y)
//    scene.add(porta) 
//    //explode(porta) 
   
}

function calcola (i){
    
    



    var yPos =  Math.trunc(i / 20)  
    
    var xPos = (i % 20)  -10


    return  {x: xPos /5 , y: yPos /5  }




}



function explode(oggetto){

    const goX = (Math.random() -0.5) * 10
    const goY = 0// (Math.random() -0.5) * 10
    const goZ = (Math.random() -0.5) * 10
    const rotX = Math.random() * Math.PI
    const rotZ = Math.random() * Math.PI
   
    gsap.to(oggetto.position,{
        duration: 2 , 
        delay:2 , 
        x: goX ,
        y: goY ,
        z: goZ  ,
    })
    gsap.to(oggetto.rotation,{
        duration: 2 , 
        delay:2 , 
        x: rotX ,
        z: rotZ
         
    })
}

console.timeEnd("cubo")



const cube = new THREE.Mesh(
    new THREE.BoxGeometry(1, 1, 1),
    new THREE.MeshBasicMaterial()
)

//scene.add(cube)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 4

scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()